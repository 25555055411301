<template>
  <div data-cy="portfolio-new-form">
    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> Portfolio Information</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup
          v-if="$auth.isAdmin"
          id="companyId"
          v-model="form.companyId"
          label="Company (Admin Only)"
          type="select"
          placeholder="N/A"
          :options="companies.map(company => ({ label: company.name, value: company._id }))"
          @input="onChangeCompany"
        />

        <FormGroup
          v-if="$permissions.isCompanyUser()"
          id="type"
          v-model="form.type"
          label="Type"
          type="select"
          placeholder="N/A"
          :options="
            !$permissions.isCompanyUser()
              ? [{ label: 'Individual Assets', value: 'custom' }]
              : [
                  { label: 'Entity', value: 'entity' },
                  { label: 'Company', value: 'company' },
                  { label: 'Individual Assets', value: 'custom' }
                ]
          "
        />

        <FormGroup id="name" v-model="form.name" :disabled="disabled" :error="error['name']" label="Name" type="text" />
        <FormGroup
          v-if="form.type === 'entity'"
          id="entityIds"
          v-model="form.entityIds"
          label="Entity"
          type="select-array-list"
          placeholder="N/A"
          :options="entities.map(entity => ({ label: entity.legalName, value: entity._id }))"
          overflow
          :search="true"
          :error="error.entityIds"
          @input="() => onChangeName(selectedEntity ? selectedEntity.legalName : null)"
        />
        <p v-if="selectedCompany && form.type === 'company'">
          This portfolio will contain all assets that are under the company <strong>{{ selectedCompany.name }}</strong
          >. Only Company users can view this portfolio.
        </p>
        <p v-if="form.type === 'entity'">
          This portfolio will contain all assets that are under the selected entities. Any entity users associated with these entites will be able to
          see this portfolio, as well as all company users.
        </p>
        <div v-if="form.type === 'custom'">
          <p>By default, this portfolio will only be visible to you.</p>
          <p>If you would like other users to see this portfolio, add them below.</p>
        </div>

        <FormGroup
          v-if="form.type === 'custom'"
          id="userSubs"
          v-model="form.userSubs"
          label="Users"
          type="select-array-list"
          placeholder="N/A"
          :options="
            users
              .filter(user => user.userSub !== $auth.user.sub)
              .map(user => ({ label: `${user.name} ${user.entity ? `(${user.entity.legalName})` : ''}`, value: user.userSub }))
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Form from './Form';

export default {
  name: 'PortfolioForm',
  extends: Form,
  computed: {
    ...mapGetters({
      entities: 'entity/entities',
      companies: 'company/companies',
      users: 'company/users',
      loadingAction: 'entity/loadingAction'
    }),
    selectedCompany() {
      if (!this.form.companyId) return null;

      const company = this.companies.find(c => c._id === this.form.companyId);

      return company;
    }
  },
  watch: {
    'form.type'(newType, oldType) {
      if (oldType === 'entity' && newType !== 'entity') {
        this.form.entityIds = [];
        this.form.userSubs = [];
      }
      if (newType === 'entity' && oldType !== 'entity') {
        this.form.entityIds = [];
      }
      this.clearFormErrors();
    },
    // Make sure there's not a mixture of entity users
    'form.userSubs'(subs, oldSubs) {
      const users = this.users.filter(user => subs.includes(user.userSub));

      const entityIds = users.reduce((acc, user) => {
        return user.entityId && !acc.includes(user.entityId) ? [...acc, user.entityId] : acc;
      }, []);

      if (entityIds.length > 1) {
        this.form.userSubs = oldSubs;

        this.$toasted.error('You can not add users from different entities. All entity users must be from the same entity.', {
          duration: 6000
        });
      } else if (entityIds.length > 0) {
        this.form.entityIds = [entityIds[0]];
      }
    }
  },
  async mounted() {
    this.listEntities({ data: { params: { $select: '_id,legalName', $limit: 10000 } } });
    this.listUsers({ id: this.form.companyId || this.$auth.companyId });
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/list',
      listUsers: 'company/listUsers'
    }),
    ...mapMutations({
      clearFormErrors: 'assetGroup/CLEAR_FORM_ERRORS'
    }),
    onChangeName(name) {
      if (name) this.form.name = name;
    },
    onChangeCompany(id) {
      this.listUsers({ id });
    }
  }
};
</script>
